var defaultConf = require('./config-default');
var BO_URL = 'ephj2023-data.ms-dev.mobile-spot.com'; // 'mom202201-data-dev.mobile-spot.com' // to test google maps;
var NODE_BACKEND_DOMAIN = 'ephj-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'ephj-node-backend-ws-dev.mobile-spot.com  ';
var LOCAL_NODE_BACKEND_DOMAIN = 'http://localhost:3082';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/palexpo/ephj/ephj2023/ephj2023-data.ms-dev.mobile-spot.com',
  // '/home/www/safi/mom/mom0122/mom202201-data-dev.mobile-spot.com',
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  BO_SSH_PORT: '43122',
  DEPLOY_PATH: '/home/legacy/palexpo/ephj/ephj2023/ephj2023.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-24',
  GOOGLE_ANALYTICS_ID_V4: '',
  projectId: '970094533591',
  // FCM sender id
  appId: '9C686-109FC',
  // pushwoosh appid

  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'https://services.mobile-spot.com/api/exportNotesByMail',
    // 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    MODE: 'peering',
    // (peering or login)
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites") // `${LOCAL_NODE_BACKEND_DOMAIN}/synchro-favorites`, //
  },

  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  SSO: {
    SSO_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/sso-request") // `${LOCAL_NODE_BACKEND_DOMAIN}/sso-request`, //
  },

  SOCIAL: {
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    FACEBOOK2: {
      POST_PER_PAGE: 5
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social") //  `${LOCAL_NODE_BACKEND_DOMAIN}/social`, //
  },

  SECURED_PDF: {
    SECURED_PDF_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/secured-pdf-viewer") // `${LOCAL_NODE_BACKEND_DOMAIN}/secured-pdf-viewer`, //
  },

  PUBLIC_PDF: {
    PUBLIC_PDF_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/public-pdf-viewer") // `${LOCAL_NODE_BACKEND_DOMAIN}/public-pdf-viewer`, //
  },

  CONTRIBUTIONS_FEED: {
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/contributions-feed") // `${LOCAL_NODE_BACKEND_DOMAIN}/contributions-feed`, //
  }
});

;