import _defineProperty from "/Users/vagrant/git/mspot-ephj-2023/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
/**
 *
 * Configuration describing how 2 pages are displayed simultaneously, screen being vertically split
 *
 */

import config from 'app-customs/config/config';
import {
// AIRCRAFT_PAGE_KEY,
EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, LIST_PAGE_KEY, HOME_PAGE_KEY, NEWPRODUCT_PAGE_KEY,
// RESTAURANT_PAGE_KEY,
SERVICE_PAGE_KEY,
// EXPERIENCE_ITEM_PAGE_KEY,
// DOCUNIT_PAGE_KEY
LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys.js';
import {
// DATA_TYPE_ANIMATIONS,
// DATA_TYPE_DOCUNITS,
DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES,
// DATA_TYPE_AIRCRAFT_CATEGORIES,
DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_EVENTS } from 'app-customs/config/dataConfig';
import { get as getProfile } from 'src/core/Profile';
var _require = require('./profiles'),
  DEFAULT_PROFILE = _require.DEFAULT_PROFILE,
  WEBAPP_EXH_PROFILE = _require.WEBAPP_EXH_PROFILE,
  WEBAPP_EVT_PROFILE = _require.WEBAPP_EVT_PROFILE,
  WEBAPP_PRODUCT_PROFILE = _require.WEBAPP_PRODUCT_PROFILE,
  WEBAPP_MAP = _require.WEBAPP_MAP;

// Render state of feature that depends on profile
function FEATURE_PROFILE(profile) {
  var FEATURE_ACTIV;
  switch (profile) {
    case DEFAULT_PROFILE:
      FEATURE_ACTIV = true;
      break;
    case WEBAPP_EXH_PROFILE:
      FEATURE_ACTIV = true;
      break;
    case WEBAPP_EVT_PROFILE:
      FEATURE_ACTIV = true;
      break;
    case WEBAPP_PRODUCT_PROFILE:
      FEATURE_ACTIV = true;
      break;
    case WEBAPP_MAP:
      FEATURE_ACTIV = false;
      break;
    default:
      FEATURE_ACTIV = false;
      break;
  }
  return FEATURE_ACTIV;
}
export var FEATURE_ACTIVATED = FEATURE_PROFILE(getProfile());

/**
 * Required min width to auto-enable this mode
 * @type {Number}
 */

export var MIN_WIDTH = 800;
export var AREA = {
  right: 'right',
  left: 'left'
};

/**
 * Default area for all pages
 */
export var TWO_COLUMNS_DEFAULT = AREA.left;

/**
 * Every page NOT declared here will rely on `TWO_COLUMNS_DEFAULT`
 */

export var PAGES_SETUP = PAGES_SETUP_PROFILE(getProfile());
function PAGES_SETUP_PROFILE(profile) {
  var pageSetup;
  switch (profile) {
    case WEBAPP_EVT_PROFILE:
      pageSetup = _defineProperty({}, EVENT_PAGE_KEY, AREA.right);
      break;
    default:
      pageSetup = _defineProperty({}, config.MAP.DEFAULT_MAP_PAGE_KEY, AREA.right);
  }
  return pageSetup;
}

/**
 * Indicate in which columns a page should be displayed
 * @param  {string} pageKey
 * @return {string}
 * @see    pagesTwoColumnsConfig.AREA
 */
export var getArea = function getArea(pageKey) {
  return PAGES_SETUP[pageKey] || TWO_COLUMNS_DEFAULT;
};

/**
 * Toolbar is displayed on a single area. Define here on which one
 */
export var TOOLBAR_AREA = AREA.left;

/**
 * CONFIGURE THIS FOR EVERY PROJECT
 */
export function getSecondPage(displayedPageKey, displayedPageProps, profile) {
  var displayedPageArea = getArea(displayedPageKey);
  var profiles = getProfile();

  // If displayed page is on left side, then display Map on the right side
  if (displayedPageArea === AREA.left) {
    var pageKey;
    switch (profiles) {
      case WEBAPP_EVT_PROFILE:
        pageKey = {
          pageKey: EVENT_PAGE_KEY
        };
        break;
      default:
        pageKey = {
          pageKey: config.MAP.DEFAULT_MAP_PAGE_KEY
        };
        break;
    }
    return pageKey;
  }
  switch (displayedPageKey) {
    case EVENT_PAGE_KEY:
      return {
        pageKey: LIST_GROUPS_PAGE_KEY,
        props: {
          locateAll: false,
          inputs: [{
            dataType: DATA_TYPE_EVENTS
          }]
        }
      };
    case EXHIBITOR_PAGE_KEY:
      return {
        pageKey: LIST_PAGE_KEY,
        props: {
          locateAll: false,
          inputs: [{
            dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
          }]
        }
      };

    // case AIRCRAFT_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_AIRCRAFT_CATEGORIES }]},
    //     };

    case NEWPRODUCT_PAGE_KEY:
      return {
        pageKey: LIST_PAGE_KEY,
        props: {
          inputs: [{
            dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
          }]
        }
      };

    // case EXPERIENCE_ITEM_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_ANIMATIONS }]},
    //     };

    // case DOCUNIT_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_DOCUNITS }]},
    //     }

    // case PAPER_PAGE_KEY:
    //     return {
    //         pageKey: LIST_PAGE_KEY,
    //         props  : { inputs: [{ dataType: DATA_TYPE_PAPER_CATEGORIES }]},
    //     };

    case SERVICE_PAGE_KEY:
      return {
        pageKey: LIST_PAGE_KEY,
        props: {
          locateAll: false,
          inputs: [{
            dataType: DATA_TYPE_SERVICE_CATEGORIES
          }]
        }
      };
    case config.MAP.DEFAULT_MAP_PAGE_KEY:
      return config.getHomePage(profile);
  }
  return {
    pageKey: LIST_PAGE_KEY,
    props: {
      locateAll: false,
      inputs: [{
        dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
      }]
    }
  };
}

/**
 * Declare fiche page keys for which POI should be automatically displayed on map
 * @type {Array}
 */
export var AUTOSHOW_POI_ON_MAP = AUTOSHOW_POI_ON_MAP_PROFILE(getProfile());
function AUTOSHOW_POI_ON_MAP_PROFILE(profile) {
  var _autoShow;
  var autoShow;
  switch (profile) {
    case WEBAPP_EVT_PROFILE:
      autoShow = {
        //    [AIRCRAFT_PAGE_KEY]  : true,
        //[EVENT_PAGE_KEY]: true,
        //[EXHIBITOR_PAGE_KEY]: true,
        // // [RESTAURANT_PAGE_KEY]: true,
        // [SERVICE_PAGE_KEY]: true,
      };
      break;
    default:
      autoShow = (_autoShow = {}, _defineProperty(_autoShow, EVENT_PAGE_KEY, true), _defineProperty(_autoShow, EXHIBITOR_PAGE_KEY, true), _defineProperty(_autoShow, SERVICE_PAGE_KEY, true), _autoShow);
  }
  return autoShow;
}